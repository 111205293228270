<template>
  <div class="app-drag">
    <div class="searchArea">
      <template v-if="sourceType !== 'assembly'">
        <el-row :gutter="5">
          <el-col :span="24">
            <div class="disfr ac">
              <el-input
                placeholder="请输入搜索内容"
                v-model="searchData.keyword"
                class="input-with-select"
                @keydown.native.enter="handleSearch()"
                clearable
                style="width: 300px"
              >
                <el-select
                  slot="prepend"
                  v-model="searchData.chooseType"
                  placeholder="请选择类型"
                  style="width: 100px"
                >
                  <el-option label="应用名称" value="apkName"> </el-option>
                  <el-option label="包名" value="packageName"> </el-option>
                </el-select>
                <el-button
                  type="primary"
                  slot="append"
                  icon="el-icon-search"
                  @click="handleSearch()"
                ></el-button>
              </el-input>
              <div
                style="margin-left: 20px"
                class="disfr ac"
                v-if="sourceType == 'gp'"
              >
                <div style="width: 50px">分类：</div>
                <el-cascader
                  style="width: 150px"
                  placeholder="请选择分类"
                  v-model="searchData.cateId"
                  :options="apkCategoryListGp"
                  :show-all-levels="false"
                  clearable
                  :props="{
                    children: 'list',
                    label: 'cateName',
                    value: 'cateId',
                    emitPath: false,
                  }"
                  @change="handleSearch()"
                ></el-cascader>
              </div>
              <div
                style="margin-left: 20px"
                class="disfr ac"
                v-if="sourceType != 'gp'"
              >
                <div style="width: 50px">分类：</div>
                <el-cascader
                  style="width: 150px"
                  placeholder="请选择分类"
                  v-model="searchData.cateId"
                  :options="apkCategoryLista"
                  :show-all-levels="false"
                  clearable
                  :props="{
                    children: 'list',
                    label: 'cateName',
                    value: 'cateId',
                    emitPath: false,
                  }"
                  @change="handleSearch()"
                ></el-cascader>
              </div>
              <div
                class="disfr ac"
                style="margin-left: 10px"
                v-if="sourceType !== 'gp'"
              >
                <div style="width: 50px">语言：</div>
                <el-select
                  slot="prepend"
                  v-model="searchData.language"
                  placeholder="请选择语言"
                  style="width: 120px"
                  @change="handleSearch()"
                  clearable
                >
                  <el-option
                    v-for="item in languageDataList"
                    :key="item.languageType"
                    :label="item.languageName"
                    :value="item.languageType"
                  ></el-option>
                </el-select>
              </div>
              <div
                style="margin-left: 20px"
                class="disfr ac"
                v-if="sourceType == 'gp'"
              >
                <div style="width: 50px">语言：</div>
                <el-select
                  slot="prepend"
                  v-model="searchData.language"
                  placeholder="请选择语言"
                  style="width: 120px"
                  @change="handleSearch"
                  clearable
                >
                  <el-option
                    v-for="item in countryList"
                    :key="item.languageType"
                    :label="item.languageName"
                    :value="item.languageType"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </el-col>
        </el-row>
      </template>
      <template v-else>
        <el-input
          v-model="searchData.assembly_name"
          placeholder="请输入组件名称"
          class="mr10 mb10"
        ></el-input>
        <el-button
          class="mb10"
          type="primary"
          icon="el-icon-search"
          @click="handleSearch()"
          >查询</el-button
        >
      </template>
    </div>
    <el-row class="scroll-card" ref="appListRef" v-loading="loading">
      <draggable
        v-model="promotionList"
        :group="{ name: 'promotion', pull: 'clone', put: false }"
        :animation="150"
        :scroll="true"
        :sort="false"
        :forceFallback="true"
        :scrollSensitivity="50"
        v-if="promotionList.length"
      >
        <el-col :span="12">
          <AppCard
            :data="promotionList[0]"
            :isShowDeployed="false"
            :moduleCode="moduleCode"
            :sourceType="sourceType"
          >
          </AppCard>
        </el-col>
      </draggable>
      <draggable
        v-model="appList"
        filter=".line_active"
        :group="{ name: 'app', pull: 'clone', put: false }"
        :animation="150"
        :scroll="true"
        :sort="false"
        :forceFallback="true"
        :scrollSensitivity="50"
      >
        <el-col
          :span="12"
          :class="{ line_active: item.isDeployed && styleCode != 201 }"
          v-for="(item, index) in appList"
          :key="index"
        >
          <AppCard
            :data="item"
            :isShowDeployed="
              styleCode == 201 || styleCode == 202 ? false : true
            "
            :moduleCode="moduleCode"
            :sourceType="sourceType"
          ></AppCard>
        </el-col>
      </draggable>
    </el-row>
  </div>
</template>
<script>
import {
  queryApkLabelList,
  queryApkCategoryLevelList,
  queryApkGpCategoryLevelList,
} from "@/api/componentManage";

import PageMore from "@/common/utils/pageMore";
import AppCard from "./AppCard.vue";

import { getLanguageList, selectApplyList } from "@/api/common";
import { getCompanyId, getResType } from "@/common/utils/utils";
import { countryList } from "@/common/constant/constant";

export default {
  name: "AppDrag",
  components: { AppCard },
  props: {
    // 已选择的app列表
    selectList: {
      type: Array,
      default() {
        return [];
      },
    },
    // 应用来源：app--自营应用；yyb--应用宝；order--预约应用；assembly--组件;
    sourceType: {
      type: String,
      default: "app",
    },
    // 部署时是否需要添加图片
    needImg: {
      type: Boolean,
      default: false,
    },
    // 部署时是否需要添加视频
    needVideo: {
      type: Boolean,
      default: false,
    },
    styleCode: {
      //样式
      type: Number,
      default: 103,
    },
    moduleCode: {
      //备注
      type: String,
      default: "",
    },
    isShowDesttop: {
      //是否来着桌面管理页面
      type: Boolean,
      default: false,
    },
    tabulatioName: {
      //列表名称
      type: String,
      default: "",
    },
    blockId: {
      type: String,
      default: "",
    },
    otherSearchShow: Boolean,
  },
  data() {
    return {
      countryList,
      searchData: {
        chooseType: "apkName",
        keyword: "",
      },
      page: 1,
      labelList: [],
      appList: [],
      promotionList: [],
      loading: false,
      apkLabelList: [], // 标签列表
      apkCategoryLista: [], // 分类列表
      languageDataList: [], // 语言列表
      apkCategoryListGp: [], // 分类列表
    };
  },
  mounted() {
    this.getDetailList();
    this.initScrollPage();

    if (this.otherSearchShow) {
      // 标签
      queryApkLabelList({}).then((res) => {
        this.apkLabelList = res.data;
      });
    }

    queryApkCategoryLevelList({}).then((res) => {
      this.apkCategoryLista = res.data;
    });
    queryApkGpCategoryLevelList({}).then((res) => {
      this.apkCategoryListGp = res.data;
    });
    this.getLanguageData();
  },
  methods: {
    getLanguageData() {
      getLanguageList({}).then((res) => {
        if (res && res.code == 0) {
          this.languageDataList = res.data;
        }
      });
    },

    handleSearch(params = {}) {
      this.page = 1;
      this.getDetailList({ page: 1, ...params });
      this.$refs.appListRef.$el.scrollTop = 0; // 查询后滚动到顶部
    },
    async getDetailList(params, isAdd) {
      let data = {
        type: this.sourceType,
        page: this.page,
        ...this.searchData,
        ...params,
      };
      const keyword = data.keyword.trim();
      if (keyword) {
        data[data.chooseType] = keyword;
      }
      data.companyType = getCompanyId(this.sourceType);
      data.resType = getResType(this.sourceType);
      if (data.language == "") delete data.language;

      this.loading = true;
      let res = await selectApplyList(data);
      res = res.data;

      this.loading = false;
      const list = (res.list || []).map((item) => {
        item.sourceType = this.sourceType; // 来源类型
        item.resType = data.resType; // 资源类型
        // 需要添加图片
        if (this.needImg) {
          item.imgList = [];
        }
        // 是否需要添加视频
        item.needVideo = this.needVideo;
        // 判断是否为已部署，需要放在赋值来源类型后面，因为判断中有涉及到来源
        item.isDeployed = this.checkIsDeployed(item);
        return item;
      });
      if (isAdd) {
        this.appList = this.appList.concat(list);
      } else {
        this.appList = list;

        if (this.sourceType == "app") {
          if (this.blockId == "02") {
            this.promotionList.unshift({
              resType: "3002",
              resName: "推广-正方形运营位-大图",
              sourceType: "promotion",
            });
          } else if (this.blockId == "03") {
            this.appList.unshift({
              resName: "推广-正方形运营位-ICON",
              resType: "3003",
              sourceType: "promotion",
            });
          } else if (this.blockId == "04") {
            this.appList.unshift({
              resName: "推广-全局搜索-常用应用",
              resType: "3004",
              sourceType: "promotion",
            });
          } else if (this.blockId == "05") {
            this.appList.unshift({
              resName: "推广-右一屏-常用应用",
              resType: "3005",
              sourceType: "promotion",
            });
          } else if (this.blockId == "52") {
            this.appList.unshift({
              resName: "推广-应用详情页",
              resType: "3006",
              sourceType: "promotion",
            });
          } else if (this.blockId == "51") {
            this.appList.unshift({
              resType: "1003",
              resName: "推广-搜索",
              sourceType: "promotion",
            });
          } else if (this.blockId == "00") {
            this.appList.unshift({
              resType: "1001",
              resName: "推广-装机必备",
              sourceType: "promotion",
            });
          } else {
            if (this.styleCode !== 201 && this.styleCode !== 105) {
              this.appList.unshift({
                resType: "1002",
                resName: "推广-推荐位",
                sourceType: "promotion",
              });
            }
            if (this.isShowDesttop && this.tabulatioName == "云文件夹") {
              this.appList.unshift({
                resType: "3001",
                resName: "推广-云文件夹",
                sourceType: "promotion",
              });
            }
          }
        }
      }
    },
    async handleScroll() {
      this.page++;
      this.getDetailList({}, true);
    },
    initScrollPage() {
      new PageMore({
        dom: this.$refs.appListRef.$el,
        callBack: this.handleScroll,
      });
    },
    checkIsDeployed(item) {
      let isDeployed = false;
      isDeployed =
        this.selectList.findIndex((i) => i.apkId == item.apkId) != -1;
      return isDeployed;
    },
    // 暴露给父组件直接调用，重新查询数据
    init(params) {
      this.handleSearch(params);
    },
  },
  watch: {
    selectList: {
      immediate: true,
      handler() {
        // 已选择的列表变化时，重新检测当前tab页list的部署情况
        this.appList.map((item) => {
          if (item.apkId) item.isDeployed = this.checkIsDeployed(item);
          return item;
        });
      },
    },
  },
};
</script>
<style scoped lang="scss">
.app-drag {
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .scroll-card {
    flex: 1;
    padding: 10px 0px;
    overflow: auto;
    border-top: 1px solid #dfdfdf;
  }
}

// 拖拽时占位符的样式，在接收该拖拽元素的元素写ghostClass="ghostClass"
.ghostClass {
  width: 100% !important;
}

.searchArea {
  display: flex;
  flex-wrap: wrap;

  .el-input {
    width: 150px;
  }
}
</style>
