<template>
  <div>
    <draggable
      class="appReceiver"
      v-model="list"
      group="promotion"
      :animation="150"
      :scroll="true"
      :sort="true"
      :scrollSensitivity="50"
      :forceFallback="true"
      @change="appDragChange"
      handle=".dragItem"
    >
      <div v-for="(item, index) in list" :key="index" class="listItem borderA">
        <div v-if="index == 0 || index == 1">
          <div class="msg">
            <p class="title">位置{{ index + 1 }}:</p>
          </div>
          <div class="msg">
            <p class="title">部署图片</p>
            <UploadImg
              :limSize="2"
              v-model="item.imgUrl"
              :limWidth="316"
              :limHeight="316"
              :acceptType="['png', 'jpg', 'jpeg']"
              @success="
                (file) => {
                  list[index].imgUrl = file.accessPath;
                }
              "
            >
              <p slot="hint" class="hint">
                大小：2MB以内；格式：PNG,JPG,JPEG；尺寸316*316
              </p>
            </UploadImg>
          </div>
          <div class="msg">
            <p class="title">部署内容</p>
            <el-radio-group v-model="item.radio">
              <el-radio :label="1">应用</el-radio>
              <el-radio :label="2">外部链接</el-radio>
            </el-radio-group>
          </div>
          <div class="msg">
            <draggable
              v-if="item.radio === 1"
              :list="item.dragList"
              class="msg_drag"
              :group="{ name: 'nestApp', pull: true, put: ['app', 'nestApp'] }"
              ghost-class="ghost"
              chosen-class="chosen"
              :animation="150"
              :scroll="true"
              :sort="true"
              :scrollSensitivity="50"
              :forceFallback="true"
              @change="msgDragChange($event, index)"
            >
              <div
                v-for="(item, blockIndex) in item.dragList"
                :key="blockIndex"
                class="tag"
              >
                <div class="item">
                  <div class="app" v-if="item.apkName">
                    <div>
                      <p>
                        <span
                          class="tag-read"
                          @click="copy(item.apkName)"
                          :data-clipboard-text="item.apkName"
                          >{{ item.apkName }}</span
                        >
                      </p>
                      <p>
                        <span
                          class="tag-read"
                          @click="copy(item.packageName)"
                          :data-clipboard-text="item.packageName"
                          >包名：{{ item.packageName }}</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
                <span
                  class="delete"
                  @click="toDelete(index)"
                  v-if="item.apkName"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </div>
            </draggable>
            <el-input
              v-if="item.radio == 2"
              placeholder="请输入以https://开头的外部链接"
              v-model="item.webUrl"
            >
            </el-input>
          </div>
        </div>
        <div v-else>
          <i class="el-icon-delete deleteIcon" @click="removeItem(index)"></i>
          <!-- 防止按钮点击不了的bug赋予可移动的类dragItem -->

          <div class="dragItem">
            <div class="appInfo">
              <el-tag
                class="fixed-tag"
                :type="tagType(item.resType, item.sourceType)"
              >
                <span>{{ typeDesc(item.sourceType) }}</span>
              </el-tag>
              <div class="item-left" @click="handleClickSort(index)">
                <el-input
                  :key="item.assembly_id"
                  v-model="sortNumber"
                  v-if="sortIndex === index"
                  @blur="handleInputBlur"
                  @keyup.enter.native="handleInputEnter(index)"
                />
                <span v-else>{{ index + 1 }}</span>
              </div>
              <div class="item-right">
                <!-- 显示组件 -->
                <template v-if="item.sourceType == 'assembly'">
                  <div>
                    {{ item.assembly_name }}
                  </div>
                  <div>组件id：{{ item.assembly_id }}</div>
                </template>
                <!-- 显示应用宝API -->
                <template v-if="item.sourceType == 'yybApi'">
                  <div>场景：{{ item.slotDesc }}</div>
                  <div>数量：{{ item.num }}</div>
                  <div v-if="item.hasLabel == undefined || item.hasLabel">
                    标签：{{ item.label }}
                  </div>
                </template>
                <!-- 占位符： -->
                <template v-if="item.resName && item.resName != '0'">
                  <div>{{ item.resName }}</div>
                </template>
                <!-- 显示应用 -->
                <template
                  v-if="!['assembly', 'yybApi'].includes(item.sourceType)"
                >
                  <div>
                    {{ item.apkName }}
                  </div>
                  <div>{{ item.packageName }}</div>
                  <div style="margin-top: 8px" v-if="styleCode == 103">
                    <el-checkbox v-model="item.isChecked">是否选中</el-checkbox>
                  </div>
                </template>
                <slot name="itemRight" :item="item"></slot>
              </div>
            </div>
            <!-- <ImgReceiver
          v-if="item.imgList"
          class="imageReceive"
          v-model="item.imgList"
        ></ImgReceiver> -->
          </div>
          <!-- <div class="videoArea" v-if="item.needVideo">
        <el-input
          v-model="item.video_url"
          placeholder="视频链接地址"
        ></el-input>
        <UploadFile
          type="video"
          class="uploadFile"
          :acceptType="['mov', 'mp4']"
          v-model="item.video_url"
        ></UploadFile>
      </div> -->
          <slot name="itemBottom" :item="item"></slot>
        </div>
      </div>
    </draggable>
  </div>
</template>
<script>
// import ImgReceiver from "@/components/ImgReceiver";
// import UploadFile from "@/components/UploadFile";
import { sourceTypeList1 } from "@/common/constant/constant";
import UploadImg from "@/components/UploadImg";
export default {
  name: "AppReceiver",
  components: {
    UploadImg,
    // ImgReceiver, UploadFile
  },
  model: {
    prop: "data",
    event: "change",
  },
  props: {
    data: {
      type: Array,
    },
    styleCode: {
      type: [String, Number],
    },
    // 是否只能部署一个应用
    isSingle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: this.data,
      sortIndex: "", // 正在编辑sort的index
      sortNumber: "", //新的排序号
    };
  },
  methods: {
    tagType(key, sourceType) {
      if (sourceType === undefined) sourceType = "promotion";
      return (
        (sourceTypeList1.find((item) => item.name === sourceType) || {}).tag ||
        key
      );
    },
    typeDesc(key, __find = "name") {
      if (key === undefined) key = "promotion";
      return (
        (sourceTypeList1.find((item) => item[__find] === key) || {}).label ||
        key
      );
    },
    // 右侧拖拽发生改变 --- 图片/视频
    msgDragChange(row, blockIndex) {
      console.log(row);
      if (row.added) {
        const { element } = row.added;
        // 拖拽列表只允许有一个
        this.list[blockIndex].dragList = [element];
      }
    },
    appDragChange(row) {
      // 某些组件类型，只能部署一个应用
      if (row.added && this.isSingle) {
        if (this.list.length > 1) {
          this.$message.error("该组件类型只能部署一个应用");
          const { newIndex } = row.added;
          this.list.splice(newIndex, 1);
        }
      }
    },
    handleClickSort(index) {
      this.sortIndex = index;
      this.sortNumber = index + 1;
    },
    handleInputEnter(index) {
      if (!parseInt(this.sortNumber)) {
        // nextSort必须是数字
        alert("请输入数字");
        return;
      }
      // 回车保存排序
      this.updateNumber(index, this.sortNumber);
      this.sortIndex = "";
    },
    handleInputBlur() {
      // 失去焦点取消编辑
      this.sortIndex = "";
    },
    removeItem(index) {
      this.list.splice(index, 1);
    },
    updateNumber(preIndex, nextSort) {
      const sortIndex = parseInt(nextSort) - 1;
      if (sortIndex < 0) {
        return false;
      }
      const preItem = this.list.splice(preIndex, 1)[0]; // 从数组中删除该数据
      this.list.splice(sortIndex, 0, preItem); // 将数据插入到对应的位置
    },
    // 删除右侧部署内容
    toDelete(index) {
      this.list[index].dragList = [];
    },
  },
  watch: {
    data() {
      if (this.list === this.data) {
        return;
      }
      this.list = this.data;
    },
    list() {
      this.$emit("change", this.list);
    },
  },
};
</script>
<style scoped lang="scss">
.appReceiver {
  background: url("~@/assets/images/market-app.png") center no-repeat;
}
.listItem {
  background: #fff;
  width: 100%;
  padding: 15px;
  position: relative;
  box-sizing: border-box;
  .imageReceive {
    width: 80%;
    height: 150px;
    margin-top: 10px;
  }
  .appInfo {
    display: flex;
    align-items: center;
  }
  .item-left {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    background-color: #f2f2f2;
    color: #ff5722;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    font-style: italic;
    font-weight: 400;
    flex-shrink: 0;
    cursor: pointer;
  }

  .item-right {
    flex: 1;
    cursor: pointer;
  }

  .fixed-tag {
    position: absolute;
    right: 0;
    top: 0;
  }
  .deleteIcon {
    position: absolute;
    bottom: 6px;
    right: 6px;
    font-size: 20px;
    z-index: 9;
    cursor: pointer;
  }
  .videoArea {
    margin-top: 8px;
    width: 93%;
    display: flex;
    .uploadFile {
      margin-left: 10px;
    }
  }
}
</style>

<style lang="scss" scoped>
.block {
  border: 1px dashed #eeeeee;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;
  &:hover {
    border: 1px dashed #409eff;
  }
}

.msg {
  margin-bottom: 20px;
  &.bt {
    // border: 2px dashed #ccc;
    padding: 10px;
    box-sizing: border-box;
  }
  .title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .hint {
    font-size: 12px;
    color: #cccccc;
    margin: 10px 0;
  }
  .msg_drag {
    height: 105px;
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 2px dashed #67c23a;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    background: url("~@/assets/images/market-app.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
  }
}
.tag {
  margin-bottom: 0 !important;
  min-height: 80px;
  background: white;
  border: 2px dashed transparent;
  display: flex;
  align-items: center;
  .item {
    width: 100%;
    .app {
      display: flex;
      .img {
        width: 55px;
        height: 55px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      p {
        margin-bottom: 5px;
      }
    }
  }
  .delete {
    min-width: 10px;
  }
}
</style>
